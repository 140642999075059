import { reportClientError } from '/machinery/reportClientError'

export function track(data) {
  window.dataLayer = window.dataLayer || []
  try {
    if (!data) throw new Error(`No data given to track function`)
    window.dataLayer.push(removeUndefinedValues(data))
  } catch (e) {
    reportClientError(e)
  }
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}
