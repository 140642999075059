import { useElementSize } from '@kaliber/use-element-size'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

export function Button({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined }) {
  const Base = href ? ABase : ButtonBase
  return <Base className={cx(styles.component, layoutClassName)} {...{ children, dataX, onClick, href }} />
}

export function ButtonPrimary({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined }) {
  const Base = href ? ABase : ButtonBase
  return <Base className={cx(styles.componentPrimary, layoutClassName)} {...{ children, dataX, onClick, href }} />
}

export function ButtonSecondary({ children, dataX, onClick = undefined, href = undefined, layoutClassName = undefined }) {
  const Base = href ? ABase : ButtonBase
  return <Base className={cx(styles.componentSecondary, layoutClassName)} {...{ children, dataX, onClick, href }} />
}

export function ButtonIcon({ icon, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, label }) {
  const Base = href ? ABase : ButtonBase
  return (
    <Base className={cx(styles.componentIcon, layoutClassName)} {...{ dataX, onClick, href, label }}>
      <Icon layoutClassName={styles.icon} {... { icon }} />
    </Base>
  )
}

export function ButtonIconSmall({ icon, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, label }) {
  const Base = href ? ABase : ButtonBase
  return (
    <Base className={cx(styles.componentIconSmall, layoutClassName)} {...{ dataX, onClick, href, label }}>
      <Icon layoutClassName={styles.iconSmall} {... { icon }} />
    </Base>
  )
}

export function ButtonIconExpandable({ icon, dataX, onClick = undefined, href = undefined, layoutClassName = undefined, label }) {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const { size: { width }, ref } = useElementSize()

  const Base = href ? ABase : ButtonBase
  return (
    <Base
      className={cx(styles.componentIconExpandable, layoutClassName)}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      onFocus={() => setIsExpanded(true)}
      onBlur={() => setIsExpanded(false)}
      {...{ dataX, onClick, href, label }}
    >
      <span className={cx(styles.expandableContent)}>
        <Icon layoutClassName={cx(styles.icon)} {... { icon }} />
        <span
          className={cx(styles.expandableTextContent)}
          style={{
            width: isExpanded ? `${width}px` : `0px`,
            marginRight: isExpanded ? `20px` : `0px`,
            transform: isExpanded ? `translateX(0px)` : `translateX(-5px)`,
            pointerEvents: isExpanded ? 'auto' : 'none',
            opacity: isExpanded ? 1 : 0
          }}
        >
          <span className={cx(styles.inner)} {... { ref }}>{label}</span>
        </span>
      </span>
    </Base>
  )
}

function ABase({ className, dataX, children, onClick, onFocus = undefined, onBlur = undefined, onMouseEnter = undefined, onMouseLeave = undefined, href, label = undefined }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a className={cx(styles.componentABase, className)} {...{ href, onClick, onFocus, onBlur, onMouseEnter, onMouseLeave, rel, target }} aria-label={label} data-x={dataX}>
      <span className={styles.inner}>
        {children}
      </span>
    </a>
  )
}

function ButtonBase({ className, dataX, children, onClick, onFocus = undefined, onBlur = undefined, onMouseEnter = undefined, onMouseLeave = undefined, label = undefined }) {
  return (
    <button className={cx(styles.componentBase, className)} {...{ onClick, onBlur, onFocus, onMouseEnter, onMouseLeave }} aria-label={label} data-x={dataX} type='button'>
      <span className={styles.inner}>
        {children}
      </span>
    </button>
  )
}
