import styles from './Link.css'

export function Link({ href, dataX, children, layoutClassName = undefined, target = undefined, onClick = undefined }) {
  return (
    <LinkBase
      className={cx(styles.component, layoutClassName)}
      {...{ href, dataX, target, onClick }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkUnderlined({ href, dataX, children, layoutClassName = undefined, target = undefined, onClick = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentUnderlined, layoutClassName)}
      {...{ href, dataX, target, onClick }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkUnderlinedOnInteraction({ href, dataX, children, layoutClassName = undefined, target = undefined, onClick = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentUnderlinedOnInteraction, layoutClassName)}
      {...{ href, dataX, target, onClick }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkMenu({ active, href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase className={cx(styles.componentMenu, active && styles.isActive, layoutClassName)} {...{ href, target, dataX }}>
      {children}
    </LinkBase>
  )
}

export function LinkMenuMobile({ href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase className={cx(styles.componentMenuMobile, layoutClassName)} {...{ href, target, dataX }}>
      {children}
    </LinkBase>
  )
}

function LinkBase({ href, dataX, children, target = undefined, className = undefined, onClick = undefined }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : null

  return (
    <a
      data-x={dataX}
      className={cx(styles.componentBase, className)}
      {...{ href, rel, target, onClick }}
    >
      {children}
    </a>
  )
}
