export function Icon({ icon, label = undefined, layoutClassName = undefined }) {
  return (
    <span
      className={layoutClassName}
      aria-label={label}
      role={label ? undefined : 'presentation'}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}
