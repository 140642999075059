import styles from './Heading.css'

export function HeadingXs({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentXs} {...{ children, h, layoutClassName }} />
  )
}

export function HeadingXsRegular({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentXsRegular} {...{ children, h, layoutClassName }} />
  )
}

export function HeadingSm({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentSm} {...{ children, h, layoutClassName }} />
  )
}

export function HeadingMd({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentMd} {...{ children, h, layoutClassName }} />
  )
}

export function HeadingLg({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentLg} {...{ children, h, layoutClassName }} />
  )
}

export function HeadingXl({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentXl} {...{ children, h, layoutClassName }} />
  )
}

export function HeadingXxl({ children, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase className={styles.componentXxl} {...{ children, h, layoutClassName }} />
  )
}

function HeadingBase({ h, children, className, layoutClassName }) {
  const H = h === undefined ? 'strong' : `h${h}`
  // eslint-disable-next-line @kaliber/layout-class-name
  return <H className={cx(styles.componentBase, className, layoutClassName)} {...{ children }} />
}
