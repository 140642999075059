import styles from './Text.css'

export function TextSm({ children, layoutClassName = undefined }) {
  return (
    <TextBase className={styles.componentSm} {...{ children, layoutClassName }} />
  )
}

export function TextMd({ children, layoutClassName = undefined }) {
  return (
    <TextBase className={styles.componentMd} {...{ children, layoutClassName }} />
  )
}

export function TextLg({ children, layoutClassName = undefined }) {
  return (
    <TextBase className={styles.componentLg} {...{ children, layoutClassName }} />
  )
}

function TextBase({ children, className, layoutClassName }) {
  return <p className={cx(styles.componentBase, className, layoutClassName)} {...{ children }} />
}
